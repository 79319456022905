import React from 'react';
import './App.css';
import HomePage from './pages/HomePage'
import ReactGA from 'react-ga';

function App() {
  React.useEffect(()=>{
    //analytics for page views
    ReactGA.pageview(window.location.pathname + window.location.search);
  },[]);
  return (
    
    <>
    <HomePage/>
    {/* <Theme/> */}
    </>
  );
}

export default App;
