import { text } from "../constants/tailwind-theme";
import { twMerge } from 'tailwind-merge'

const ServiceItemComponent = ({
    type = "primary",
    title,
    description,
    icon
}) => {


    return (
        <div className={`flex flex-col items-center p-4 rounded-sm  ${type === "secondary" ? "bg-white" : "bg-primary-500"}`}>
            <img className="inline w-20 h-20 mb-5" src={icon} alt="icon1" />
            <h2 className={twMerge(`${text.heading2} text-center ${type === "secondary" ? "text-primary-500" : "text-secondary-500"}`)}>{title} </h2>
            <p className={`${text.body1} text-center ${type === "secondary" ? "text-primary-500" : "text-secondary-500"}`}> {description}</p>
        </div>
    )
}

export default ServiceItemComponent;