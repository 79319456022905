import { text } from "../constants/tailwind-theme";
import { twMerge } from 'tailwind-merge'
import ReactGA from 'react-ga';

const PortfolioItemComponent = ({
    title,
    url,
    image,
    selectedTag,
    tags=[]
}) => {
    if(tags.includes(selectedTag)){

        return (
            <div style={{ backgroundImage: `url(${image})`,backgroundSize:"cover"  }} className="flex justify-center items-center  aspect-square border-2 rounded-md border-primary-500  w-60">
                <div className="flex flex-col bg-primary-500 w-full h-full justify-center items-center transition duration-500 hover:bg-primary-500 hover:opacity-80 opacity-0">
                    <h2 className={twMerge(`${text.heading2} text-white text-center mb-3`)}>{title}</h2>
                    <a onClick={()=>{ ReactGA.event({category:"OUR_WORK",action:title})}} target={"_blank"} href={url}> <p className={twMerge(`${text.heading3} text-white text-center`)}>See Project</p> </a>
                </div>
            </div>
           
        )
    }

    return null;

}

export default PortfolioItemComponent;