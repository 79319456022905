
export const text ={
    heading1:"font-heading font-semibold text-4xl text-primary-500",
    heading2:"font-heading font-semibold text-2xl text-primary-500",
    heading3:"font-heading font-semibold text-md text-primary-500",
    body1:"font-body font-regular text-md ",
}

export const link ={
    heading3:`${text.heading3}  hover:underline decoration-2 underline-offset-4`,
}

export const input ={
    default:`rounded-md w-80 h-12 p-4  ${text.body1}`,
}

export const button ={
    primary:`rounded-sm  bg-primary-500 active:bg-tertiary-500 justify-center items-center text-center  p-3 `,
    secondary:`rounded-sm  bg-secondary-500 active:bg-tertiary-500 justify-center items-center text-center  p-3`,
    text:"font-heading font-semibold text-md text-white text-center uppercase",
}
