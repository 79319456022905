import { text } from "../constants/tailwind-theme";

const AboutItemComponent = (props) =>
    <div className="flex flex-row items-center   ">

        <div className=" h-[5rem] rounded-sm bg-primary-500 mr-4 px-1"></div>
        <div>
            <h2 className={`${text.heading2}`}> {props.title}</h2>
            <p className={`${text.body1}`}>{props.description}</p>
        </div>

    </div>

export default AboutItemComponent;