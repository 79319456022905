import { text } from "../constants/tailwind-theme";
import { twMerge } from 'tailwind-merge'

const SectionHeaderComponent = ({
    type = "primary",
    title,
    description,
    icon
}) => {


    return (
        <div className="flex flex-col ">
            <div className="flex flex-row items-center">
                <h2 className={twMerge(`${text.heading2} mr-3 ${type === "secondary" ? "text-secondary-500" : "text-primary-500"}`)}>{title} </h2>
                <div className={twMerge(`${type==="secondary"? "bg-secondary-500":"bg-primary-500"} h-1.5 w-20 rounded-sm `)}></div>
            </div>
            <h2 className={twMerge(`${text.heading3} mr-3 ${type === "secondary" ? "text-secondary-500" : "text-primary-500"}`)}>{description} </h2>
        </div>
    )
}

export default SectionHeaderComponent;