import {  Navbar } from "flowbite-react";
import { twMerge } from "tailwind-merge";
import logo from '../assets/icons/logo.png';
import aboutus from '../assets/images/aboutus.jpg';
import banner from '../assets/images/banner.jpg';
import AboutItemComponent from "../components/AboutItemComponent";
import ServiceItemComponent from "../components/ServiceItemComponent";

import desktopicon from '../assets/icons/iconmonstr-smartphone-13-240.png'
import shopicon from '../assets/icons/shop.png'
import websiteicon from '../assets/icons/iconmonstr-computer-2-240.png'
import ghanakids from '../assets/images/ghanakids.jpg'
import contactus from '../assets/images/contactus.png'
import africa from '../assets/icons/africa.png'
import efficiency from '../assets/icons/efficiency.png'
import goaloriented from '../assets/icons/goaloriented.png'
import timelydelivery from '../assets/icons/timelydelivery.png'

import portfolioimage from '../assets/images/IMG_2901.jpg'
import portfolioimage2 from '../assets/images/portfolio2.jpeg'
import portfolioimage4 from '../assets/images/IMG_2902.jpg'
import portfolioimage3 from '../assets/images/portfolio3.png'
import portfolioimage5 from '../assets/images/portfolio4.jpeg'


import { button, link, text } from "../constants/tailwind-theme";
import SectionHeaderComponent from "../components/SectionHeaderComponent";
import ValueItemComponent from "../components/ValueItemComponent";

import { FaWhatsappSquare, FaTwitterSquare, FaInstagramSquare, FaFacebookSquare } from 'react-icons/fa'
import PortfolioItemComponent from "../components/PortfolioItemComponent";
import React from "react";

import ReactGA from 'react-ga';

const HomePage = (props) => {

    const [selectedWork, setSelectedWork] = React.useState("ALL")

    return (
        <div id="home" className="flex flex-col">

            {/* HEADING */}
            <Navbar
                fluid={true}

            >
                <Navbar.Brand href="/">
                    <img
                        src={logo}
                        className="h-10 "
                        alt="divcon Logo"
                    />

                </Navbar.Brand>
                <Navbar.Toggle />
                <Navbar.Collapse >
                    <Navbar.Link
                        className="bg-white"
                        href="#home"
                    // active={true}
                    >
                        <h2 className={twMerge(`${link.heading3} `)}>Home</h2>
                    </Navbar.Link>
                    <Navbar.Link href="#about">
                        <h2 className={twMerge(`${link.heading3} `)}>About</h2>
                    </Navbar.Link>
                    <Navbar.Link href="#services">
                        <h2 className={twMerge(`${link.heading3} `)}>Our Services</h2>
                    </Navbar.Link>
                    <Navbar.Link href="#values">

                        <h2 className={twMerge(`${link.heading3} `)}>Why Choose Us</h2>

                    </Navbar.Link>
                    <Navbar.Link href="#work">
                        <h2 className={twMerge(`${link.heading3} `)}>Our Work</h2>

                    </Navbar.Link>
                    <Navbar.Link href="#contact">
                        <h2 className={twMerge(`${link.heading3} `)}>Contact Us</h2>
                    </Navbar.Link>
                </Navbar.Collapse>
            </Navbar>

            {/* BANNER */}
            <div style={{ backgroundImage: `url(${banner})`, backgroundSize: "cover" }} className="flex flex-col justify-center md:h-[500px]">
                <div className="grid gap-y-8 md:w-8/12 p-10 border-0">
                    <h1 className={twMerge(`${text.heading1} text-white`)} >Welcome to HEYPHORD I.T SOLUTIONS</h1>
                    <h2 className={twMerge(`${text.heading2} text-white`)} >Our mission is to apply technology to solve real-world problems for individuals, communities, and organizations.</h2>
                    <h2 className={twMerge(`${text.heading2} text-white`)} >Let us help you resolve your organization’s software-related issues while saving you cost.</h2>
                    <a href="#contact" className={twMerge(`${button.secondary} mt-3  w-40 animate-bounce`)} >
                        <span className={twMerge(`${button.text} text-primary-500`)}>CONTACT US </span>
                    </a>
                </div>
            </div>

            {/* ABOUT US */}

            <div className="grid md:grid-cols-2 md:grid-row-3  gap-4  bg-white px-10 py-20 ">

                <div className="border-0 " >
                    <AboutItemComponent
                        title="ABOUT US"
                        description="HEYPHORD I.T SOLUTIONS is a registered Ghanaian business that deals in the sales, lease, and creation of software applications for private and commercial use."
                    />
                </div>


                <div className="border-0">
                    <AboutItemComponent
                        title="MISSION"
                        description="At HEYPHORD I.T SOLUTIONS, our mission is to apply technology to solve real-world problems for individuals, communities, and organizations."
                    />
                </div>

                <div className="border-0" >

                    <AboutItemComponent
                        title="VISION"
                        description="Our vision is to improve day-to-day life and make a meaningful impact on individuals, communities, and organizations through our technology solutions."
                    />
                </div>

                <div className=" md:col-start-2  md:row-start-1 md:row-span-3">
                    <img src={aboutus} className="w-full h-full" alt="" srcset="" />
                </div>
            </div>


            {/* OUR SERVICES */}

            <div id="services" className="grid md:grid-cols-3 gap-4 px-10 py-20   bg-gray-300">

                <div className=" md:col-span-3 pb-10">
                    <SectionHeaderComponent
                        title="OUR SERVICES"
                        description="Wondering how we can be of help to you? Here are some of the services we provide to make life easier"
                    />
                </div>
                <div className=" ">
                    <ServiceItemComponent
                        icon={websiteicon}
                        title="WEBSITE DEVELOPMENT"
                        description="Enjoy a smooth and seamless experience with our responsive website solutions."
                    />
                </div>
                <div className=" ">
                    <ServiceItemComponent
                        type="secondary"
                        icon={shopicon}
                        title="SALE AND LEASE OF COMMERCIAL SOFTWARES"
                        description="Customized software products developed for your convenience. "
                    />
                </div>
                <div className=" ">
                    <ServiceItemComponent
                        icon={desktopicon}
                        title="MOBILE APP DEVELOPMENT"
                        description="We develop mobile and desktop applications, compatible with Android And iOS."
                    />
                </div>

            </div>

            {/* WHY CHOOS US */}
            <div id="values" className="grid md:grid-cols-2 gap-10 py-20 px-10 md:bg-cover  bg-center bg-fixed" style={{ backgroundImage: `url(${ghanakids})` }} >

                <div className=" md:col-span-2">
                    <SectionHeaderComponent
                        type="secondary"
                        title="WHY CHOOSE US"
                        description="Our values set us apart from the competition. See why we should be your first choice."
                    />
                </div>

                <div className=''>
                    <ValueItemComponent
                        icon={goaloriented}
                        title="GOAL ORIENTED"
                        description="We take satisfaction in providing solutions that best suit our clients’ needs. Hence, we prioritize understanding the problem on the ground and developing a goal and an actionable strategy to solve that problem."
                    />
                </div>
                <div className=''>
                    <ValueItemComponent
                        icon={desktopicon}
                        title="CONFIDENTIALITY"
                        description="We understand that client information is of utmost confidentiality. Unless it is necessary, we do not share our client’s personal information or anything regarding their projects with a third party. In situations where we have to, we seek the consent of our clients."
                    />
                </div>
                <div className=''>
                    <ValueItemComponent
                        icon={timelydelivery}
                        title="TIMELY DELIVERY"
                        description="Trust us to deliver on time. Our team works assiduously to deliver your software solutions without any delay."
                    />
                </div>
                <div className=''>
                    <ValueItemComponent
                        icon={efficiency}
                        title="EFFICIENCY"
                        description="Our solutions are designed to deliver the best results at affordable rates. We optimize the available resources to achieve excellent results."
                    />
                </div>
                <div className=''>
                    <ValueItemComponent
                        icon={africa}
                        title="PROUDLY AFRICAN"
                        description="We want to use our products to change the narrative for Africa and the world at large. We want to make quality and affordable products that can compete in the international market."
                    />
                </div>


            </div>

            {/* OUR WORK */}
            <div id="work" className="flex flex-col space-y-10 py-20 px-10">
                <div className="col-span-full ">
                    <SectionHeaderComponent
                        // type="secondary"
                        title="OUR WORK"
                        description="Have a look at some of our in-house projects, as well as some projects our clients have allowed us to showcase. Click on the images to see more details."
                    />
                </div>

                <div className="col-span-full ">
                    <div className="flex flex-wrap space-x-5 justify-start items-center ">
                        <a onClick={() => setSelectedWork("ALL")}> <h2 className={twMerge(`${link.heading3} text-start ${selectedWork==="ALL" ? "underline":""}`)}> ALL</h2></a>
                        <a onClick={() => setSelectedWork("INHOUSE")}> <h2 className={twMerge(`${link.heading3} text-start ${selectedWork==="INHOUSE" ? "underline":""}`)}> IN-HOUSE</h2></a>
                        <a onClick={() => setSelectedWork("CLIENT")}> <h2 className={twMerge(`${link.heading3} text-start ${selectedWork==="CLIENT" ? "underline":""}`)}> CLIENTS</h2></a>
                    </div>
                </div>

                {/* Portfolio items */}

                <div className="flex flex-row flex-wrap  space-y-5 ">

                    <div className="m-3">
                        <PortfolioItemComponent
                            selectedTag={selectedWork}
                            tags={["ALL", "INHOUSE"]}
                            image={portfolioimage}
                            title="VOTE BY HEYPHORD"
                            url="https://vote.heyphord.com"
                        />
                    </div>
                    <div className="m-3">
                        <PortfolioItemComponent
                            selectedTag={selectedWork}
                            tags={["ALL", "CLIENT"]}
                            image={portfolioimage2}
                            title="DIVCON LIMITED"
                            url="https://divconlimited.com"
                        />
                    </div>
                    <div className="m-3">
                        <PortfolioItemComponent
                            selectedTag={selectedWork}
                            tags={["ALL", "CLIENT"]}
                            image={portfolioimage4}
                            title="JANIEHAYS LIMITED"
                            url="https://janiehays.com"
                        />
                    </div>
                    <div className="m-3">
                        <PortfolioItemComponent
                            selectedTag={selectedWork}
                            tags={["ALL", "CLIENT"]}
                            image={portfolioimage5}
                            title="THEMP FOUNDATION"
                            url="https://thempproject.org"
                        />
                    </div>
                   
                </div>
            </div>


            {/* CONTACT US */}
            <div id="contact" className="grid md:grid-cols-3 px-10 py-20 bg-fixed" style={{ backgroundImage: `url(${contactus})` }}>

                <div className=" md:col-span-3 pb-10">
                    <SectionHeaderComponent
                        type="secondary"
                        title="CONTACT US"
                        description="Do you have any inquiries? Go ahead and talk to us. Don't forget to follow our social media handles for more updates. "
                    />
                </div>


                <div className="border-0 mb-10">
                    <h1 className={twMerge(`${text.heading2} text-start text-white `)}>HEYPHORD I.T SOLUTIONS</h1>
                    <h2 className={twMerge(`${text.heading3} text-start text-white`)}>Madina New Road</h2>
                    <h2 className={twMerge(`${text.heading3} text-start text-white`)}>Accra, Ghana</h2>
                    <h2 className={twMerge(`${text.heading3} text-start text-white `)}>0552900095</h2>
                    <h2 className={twMerge(`${text.heading3} text-start text-white`)}>info.heyphord@gmail.com</h2>
                </div>

                <div className="mb-10">
                    <h1 className={twMerge(`${text.heading2} text-start text-white mb-4`)}>Social Media</h1>
                    <div className="flex flex-wrap">
                        <a onClick={()=>{ ReactGA.event({category:"CONTACT_US",action:"WHATSAPP"})}} href="https://wa.me/+233552900095" target={"_blank"} rel="noopener noreferrer"><FaWhatsappSquare color='white' size={40} /></a>
                        <a onClick={()=>{ ReactGA.event({category:"CONTACT_US",action:"INSTAGRAM"})}} href="https://www.instagram.com/heyphord_solns/" target="_blank" rel="noopener noreferrer"><FaInstagramSquare color='white' size={40} /></a>
                        <a onClick={()=>{ ReactGA.event({category:"CONTACT_US",action:"TWITTER"})}} href="https://twitter.com/heyphord_solns" target="_blank" rel="noopener noreferrer"><FaTwitterSquare color='white' size={40} /></a>
                        <a onClick={()=>{ ReactGA.event({category:"CONTACT_US",action:"FACEBOOK"})}} href="https://www.facebook.com/people/Heyphord-IT-Solutions/100086574910603/" target="_blank" rel="noopener noreferrer"><FaFacebookSquare color='white' size={40} /></a>

                    </div>
                </div>

                <div className="border-0 ">
                    <h1 className={twMerge(`${text.heading2} text-start text-white mb-4`)}>Links</h1>
                    <a href="#home"> <h2 className={twMerge(`${link.heading3} text-start text-white`)}>&#x2022; HOME</h2></a>
                    <a href="#about"> <h2 className={twMerge(`${link.heading3} text-start text-white`)}>&#x2022; ABOUT US</h2></a>
                    <a href="#services"> <h2 className={twMerge(`${link.heading3} text-start text-white`)}>&#x2022; OUR SERVICES</h2></a>
                    <a href="#values"> <h2 className={twMerge(`${link.heading3} text-start text-white`)}>&#x2022; WHY CHOOSE US</h2></a>
                    <a href="#work"> <h2 className={twMerge(`${link.heading3} text-start text-white`)}>&#x2022; OUR WORK</h2></a>
                    <a href="#contact"> <h2 className={twMerge(`${link.heading3} text-start text-white`)}>&#x2022; CONTACT US</h2></a>
                </div>


            </div>

            {/* FOOTER */}
            <div className="flex flex-row flex-wrap justify-between bg-primary-500 p-4">
                {/* <p className={twMerge(`${text.heading1} text-center text-white text-xs`)}>2023 Heyphord I.T Solutions. All Rights Reserved</p> */}

                <a href="#" ><p className={twMerge(`${text.heading1} text-center text-white text-xs w-full`)}>Designed & Developed By Heyphord I.T Solutions</p></a>

            </div>



        </div>
    )
}

export default HomePage;