import { text } from "../constants/tailwind-theme";
import { twMerge } from 'tailwind-merge'

const ValueItemComponent = ({
    type = "primary",
    title,
    description,
    icon
}) => {


    return (
        <div className="flex flex-col ">

            <div className="flex flex-row items-center h-11 ">
                <img className="inline h-full mr-3" src={icon} alt="icon1" />
                <h2 className={twMerge(`${text.heading2}  text-secondary-500`)}> {title} </h2>

            </div>


            <p className={`${text.body1}text-secondary-500`}> {description}</p>
        </div>
    )
}

export default ValueItemComponent;